import React, { useEffect, useRef, useState } from "react";
import Header from "./components/Header";
import Send from "./components/Send";
import "./index.css";
import { Col, Row, Spin } from "antd";
import { modelling_api } from "../../services/modelling_api";
import { CHATBOT_MODELLING_API_URL } from "../../utils/URLS";
import MessageComponent from "./components/MessageComponent";
import useCustomization from "../../context/CustomizationContext";
import { setCookie } from "../../utils/storage";
import useProjectParams from "../../context/ProjectParamsContext";
import { useSelector } from "react-redux";
import { visitorSelector } from "../../redux/reducers/visitor/visitorSlice";
import { generateRandomString } from "../../utils/Helper";

const Chat = () => {
  const { setCustomization, getBot, getCustomization, customizationLoading } =
    useCustomization();
  const { convParams, setProjectParams, setSpecificParam } = useProjectParams();

  const [scrollHeight, setScrollHeight] = useState(
    "calc(100vh - 68px - 68px) "
  );
  const visitorState = useSelector(visitorSelector);
  const [loading, setLoading] = useState(false);
  const [focus, setFocus] = useState(false);
  const [inputFocus, setInputFocued] = useState(false);
  const [visitorMsgs, setVisitorMsgs] = useState([]);
  const [messages, setMessages] = useState([]);
  const [end, setEnd] = useState(false);
  const [botMsgs, setBotMsgs] = useState([]);
  const [sessionId, setSessionId] = useState(null);
  const [answer, setAnswer] = useState("");
  const [session, setSession] = useState();
  const [visitor, setVisitor] = useState();

  const messageEnd = useRef();

  const handleWindowClick = () => {
    setFocus(false);
  };

  let session_id = localStorage.getItem("session_id");
  let user_id = convParams?.user_id;
  let project_id = convParams?.project_id;
  let bot_id = convParams?.bot_id;

  const scrollToBottom = () => {
    messageEnd.current?.scrollIntoView({ behavior: "smooth" });
  };

  const appendString = (string, msgId) => {
    setBotMsgs((prevBotMsgs) => [...prevBotMsgs, string]);
  };

  const handleMessageFromParent = (event) => {
    try {
      if (typeof event.data === "string") {
        const eventData = JSON.parse(event.data);
        if (eventData.event === "params") {
          if (
            convParams?.user_id &&
            convParams?.project_id &&
            convParams?.bot_id &&
            convParams?.identifier
          ) {
          } else {
            // setCookie("visitor_id", eventData?.identifier);
            localStorage.setItem("visitor_id", eventData?.identifier);
            setProjectParams(eventData);
          }
        } else if (eventData.event === "visitor-details") {
          setVisitor(eventData?.visitorObj);
        } else if (eventData.event === "widgetSettings") {
          const eventData = JSON.parse(event.data);
          setCustomization(eventData);
        }
      }
    } catch (error) {
      console.error("Error parsing message data:", error);
    }
  };

  useEffect(() => {
    // Add event listener to listen for messages from parent window
    window.addEventListener("message", handleMessageFromParent);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("message", handleMessageFromParent);
    };
  }, []);
  const postMessage = async (msgObj) => {
    try {
      setEnd(true);
      setAnswer("");

      let url = `${CHATBOT_MODELLING_API_URL}query?user_id=${msgObj?.user_id}&project_id=${msgObj?.project_id}&question=${msgObj?.question}&bot_id=${msgObj?.bot_id}&session_id=${msgObj?.session_id}`;

      var response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let loopRunner = true;
      let fullAnswer = "";

      while (loopRunner) {
        const { value, done } = await reader.read();
        if (done) {
          loopRunner = false;
        }
        const decodedChunk = decoder.decode(value, { stream: true });
        setAnswer((answer) => answer + decodedChunk);
        fullAnswer += decodedChunk;
      }

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: messages.length + 1,
          text: fullAnswer,
          timestamp: new Date(),
        },
      ]);
      setEnd(false);
    } catch (error) {
      console.log("error", error);
    } finally {
    }
  };

  const postFile = async (msgObj) => {
    try {
      setEnd(true);
      setAnswer("");

      let url = `${CHATBOT_MODELLING_API_URL}query?user_id=${msgObj?.user_id}&project_id=${msgObj?.project_id}&bot_id=${msgObj?.bot_id}&session_id=${msgObj?.session_id}`;
      let formData = new FormData();
      let audioFile = new File(
        [msgObj?.question],
        `${generateRandomString(5)}.mp3`
      );

      formData.append("voice_note", audioFile);

      var response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "multipart/form-data",
        },
        body: formData,
      });
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let loopRunner = true;
      let fullAnswer = "";

      while (loopRunner) {
        const { value, done } = await reader.read();
        if (done) {
          loopRunner = false;
        }
        const decodedChunk = decoder.decode(value, { stream: true });
        setAnswer((answer) => answer + decodedChunk);
        fullAnswer += decodedChunk;
      }

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: messages.length + 1,
          text: fullAnswer,
          timestamp: new Date(),
        },
      ]);
      setEnd(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  const createConv = async (obj, type) => {
    try {
      let updatedObj;
      if (convParams?.from === "iframe") {
        let visitorDetails = visitorState?.visitor;
        updatedObj = { ...visitorDetails, ...obj };
      } else {
        updatedObj = { ...visitor, ...obj };
      }
      let response = await modelling_api.post(
        `new_conversation?user_id=${obj?.user_id}&project_id=${obj?.project_id}&bot_id=${obj?.bot_id}&visitor_id=${obj?.identifier}`,
        updatedObj
      );

      localStorage.setItem("session_id", response?.data?.session_id);
      setCookie("session_id", response?.data?.session_id);
      setSpecificParam("session_id", response?.data?.session_id);
      let updatedMsg = {
        ...obj,
        session_id: response?.data?.session_id,
      };

      setSessionId(response?.data?.session_id);
      if (type === "text") {
        postMessage(updatedMsg);
      } else {
        postFile(updatedMsg);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const sendMessage = async (message, type = "text") => {
    let msg = type === "text" ? message?.msg : message;
    if (type === "file") {
    } else if (type === "text") {
      let allMsgsArray = [...visitorMsgs];
      allMsgsArray.push(msg);
      const newObject = {
        id: messages.length + 1,
        text: msg,
        timestamp: new Date(),
        isVisitor: true,
      };
      setVisitorMsgs([...visitorMsgs, msg]);
      setMessages([...messages, newObject]);
    }
    scrollToBottom();

    let session_id = localStorage.getItem("session_id") || sessionId;
    let obj = {
      bot_id: convParams?.bot_id,
      session_id: convParams?.session_id,
      user_id: convParams?.user_id,
      project_id: convParams?.project_id,
      question: msg,
      identifier:
        visitorState?.visitor?.params?.visitor_id ||
        visitorState?.visitor?.bn_identifier ||
        localStorage.getItem("visitor_id"),
    };
    if (session_id) {
      if (type === "file") {
        postFile(obj);
      } else if (type === "text") {
        postMessage(obj);
      }
    } else {
      createConv(obj, type);
    }
  };

  const getSingleConversation = async (sessionId) => {
    setLoading(true);

    try {
      let response = await modelling_api.post(
        `get_conversation?user_id=${user_id}&project_id=${project_id}&bot_id=${bot_id}&session_id=${sessionId}`
      );
      if (response?.data) {
        let questions =
          response?.data?.questions?.map((msgs) => {
            return { ...msgs, isVisitor: true };
          }) ?? [];

        let answers =
          response?.data?.answers?.map((msgs) => {
            return { ...msgs };
          }) ?? [];

        const mergedMessages = questions?.concat(answers);
        mergedMessages?.sort(
          (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
        );
        const arrayOfStrings = questions?.map((obj) => obj.text);
        setVisitorMsgs(arrayOfStrings);

        if (messages?.length === 1) {
          setMessages(messages);
        } else {
          setMessages(mergedMessages);
        }
      }
    } catch (error) {
      if (error?.response?.data?.detail === "No conversation found") {
        console.log("No Conversation found");
        // const initial_msg = {
        // text: customization?.initial_message,
        // timestamp: new Date(),
        // };
        // setMessages([initial_msg, ...messages]);
      }
    } finally {
      setLoading(false);
    }
  };

  const getConversations = async (session_id) => {
    setLoading(true);
    try {
      let response = await modelling_api.post(
        `list_conversations?user_id=${convParams.user_id}&project_id=${convParams.project_id}&bot_id=${convParams.bot_id}&visitor_id=${convParams.identifier}`
      );
      const visitorCoversations = response?.data?.filter(
        (item) => item?.visitor_id === convParams?.identifier
      );

      const currentSession = visitorCoversations[0]?.sessions?.filter(
        (session) => session?.session_id === session_id
      );

      setSession(currentSession[0]);
    } catch (error) {
      console.log("err", error);
    } finally {
      setLoading(false);
    }
  };

  const getBotSettings = async (botId, projectId) => {
    setLoading(true);
    await getBot(botId);
    setLoading(false);
  };

  useEffect(() => {
    if (
      session_id &&
      convParams?.bot_id &&
      convParams?.user_id &&
      convParams?.project_id
    ) {
      getSingleConversation(session_id);
    } else {
      setMessages([]);
      setAnswer("");
    }
  }, [convParams, session_id]);

  useEffect(() => {
    if (convParams?.user_id && session_id) {
      getConversations(session_id);
    }
  }, [convParams?.user_id, session_id]);

  useEffect(() => {
    if (bot_id && project_id) {
      getBotSettings(bot_id, project_id);
    }
    if (convParams?.from === "iframe" && bot_id && project_id) {
      getCustomization(bot_id, project_id);
    }
  }, [bot_id, project_id, convParams]);

  return (
    <Spin spinning={loading || customizationLoading}>
      <div onClick={handleWindowClick}>
        <Header />
        <div className="main-sec">
          <div className="scroll" style={{ height: scrollHeight }}>
            <Row className="chat_area_row">
              <Col span={24}>
                <MessageComponent
                  answer={answer}
                  messages={messages}
                  botMsgs={botMsgs}
                  setMessages={setMessages}
                  end={end}
                  setBotMsgs={setBotMsgs}
                  scrollToBottom={scrollToBottom}
                />
              </Col>
            </Row>
            <div ref={messageEnd} />
          </div>
        </div>
        {!session?.sessionEnded && (
          <>
            <div ref={messageEnd} />
            <Send
              setScrollHeight={setScrollHeight}
              setFocus={focus}
              setInputFocued={setInputFocued}
              sendMessage={sendMessage}
              setMessages={setMessages}
              messages={messages}
              end={end}
            />
          </>
        )}
      </div>
    </Spin>
  );
};
export default React.memo(Chat);
